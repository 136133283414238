<template>
  <div class="content">
    <div class="main-screen-section inside screen2"></div>

    <div class="container inside-page content-section">
      <h1>Патогенез мигрени<ReferenceLink text="1-6" href="#ref1" /></h1>
      <div class="content-text text-size-big mb20">
        <p>
          В 1930-х годах мигрень объясняли «Сосудистой теорией», затем в 1983
          стали объяснять «Нейрогенной теорией». Однако, по отдельности они не
          объясняют патофизиологию мигрени полностью.
        </p>
      </div>

      <div class="content-text text-size-middle mb50">
        <p>
          Согласно принятой в настоящее время «Нервно-сосудистой теории»,
          симптомы мигрени вызваны взаимодействием черепных кровеносных сосудов
          и нервной системы. Мигрень начинается с ряда неврологических событий,
          которые ведут к различным внутри- и внечерепным изменениям.
        </p>
      </div>

      <h3>Причины боли</h3>

      <div class="causes-block">
        <div class="theory-col1">
          <div class="theory">
            <div class="title">Сосудистая теория</div>
            <ul class="list-block">
              <li>сужение кровеносных сосудов в черепе</li>
              <li>вазодилатация (эффект «отдачи»)</li>
            </ul>
          </div>
          <div class="plus"></div>
          <div class="theory">
            <div class="title">Нейрогенная теория</div>
            <ul class="list-block">
              <li>
                кортикальная распространяющаяся депрессия <br />
                (кора головного мозга)
              </li>
              <li>тригеминальная система (ствол головного мозга)</li>
            </ul>
          </div>
        </div>
        <div class="theory-col2">
          <div class="theory">
            <div class="title">Нервно-сосудистая теория</div>
            <ul class="list-block">
              <li>
                кортикальная распространяющаяся <br />
                депрессия (кора головного мозга)
              </li>
              <li>тригеминальная система (ствол головного мозга)</li>
              <li>
                изменения в сосудах из-за высвобождения нейропептидов <br />
                и провоспалительных веществ
              </li>
            </ul>
          </div>
        </div>
      </div>

      <h3 class="mb30">Роль CGRP<ReferenceLink text="*" href="#ref*" /></h3>

      <div class="video-block">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/qagby2Lz_FE"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <!-- <span class="play-ico"></span> -->
      </div>

      <div class="content-text text-size-middle">
        <p>
          Мигрень — это нейровоспалительное заболевание: после активации
          тройничные чувствительные нейроны в мозговых оболочках высвобождают
          провоспалительные нейропептиды, такие как CGRP<ReferenceLink
            text="*"
            href="#ref*"
          />.
        </p>
        <p>
          Нейропептиды взаимодействуют с кровеносными сосудами в мозговых
          оболочках, провоцируя вазодилатацию и экстравазацию белков плазмы
          крови. Нейропептиды индуцируют дегрануляцию тучных клеток и
          вырабатывают дополнительные провоспалительные модуляторы. Этот процесс
          получил название «стерильное нейрогенное воспаление».
        </p>
        <p class="mb40">
          Нейрогенное воспаление влияет на то, как головной мозг воспринимает
          боль.
        </p>

        <h3 class="mb20">
          Роль CGRP<ReferenceLink text="*" href="#ref*" /> при нейрогенном
          воспалении<ReferenceLink text="7-8" href="#ref7" />
        </h3>

        <div>
          <div class="mb40">
            <div class="row">
              <div class="col-md-4">
                <div class="sub-list-title">
                  <span class="semibold">Вазодилатация</span>
                </div>
                <ul class="list-block">
                  <li>
                    CGRP<ReferenceLink text="*" href="#ref*" /> встречается по
                    всей сосудистой сети головного мозга.
                  </li>
                  <li>
                    CGRP<ReferenceLink text="*" href="#ref*" /> индуцирует
                    дилатацию средних мозговых и средних менингеальных артерий.
                  </li>
                  <li>
                    CGRP<ReferenceLink text="*" href="#ref*" /> стимулирует
                    кровоток <br />
                    во время КРД, что предшествует головной боли.
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div class="sub-list-title">
                  <span class="semibold">Экстравазация белков плазмы</span>
                </div>
                <ul class="list-block">
                  <li>
                    Белки плазмы покидают <br class="hidden-xs" />
                    твердую мозговую оболочку <br />
                    и индуцируют боль.
                  </li>
                  <li>
                    ЭБП вызывается субстанцией <br />
                    P и нейрокинином, которые высвобождаются
                    <br class="hidden-xs" />
                    одновременно с CGRP<ReferenceLink text="*" href="#ref*" />.
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div class="sub-list-title">
                  <span class="semibold">Дегрануляция тучных клеток</span>
                </div>
                <ul class="list-block">
                  <li>
                    Активация и дегрануляция тучных клеток высвобождает
                    гистамин, который может индуцировать мигрень.
                  </li>
                  <li>
                    Активация тучных клеток может активировать первичные
                    афференты <br class="visible-xs" />
                    в твердой мозговой оболочке, что приводит
                    <br class="hidden-xs" />
                    к иммунореактивности в ядре тройничного нерва.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="clinical-data">
          <div class="title">
            Клинические данные <br class="visible-xs" />
            подтверждают роль CGRP<ReferenceLink text="*" href="#ref*" />
            <br class="visible-xs" />
            при мигрени:
          </div>
          <ul class="list-block">
            <li>
              Во время приступа мигрени уровни CGRP<ReferenceLink
                text="*"
                href="#ref*"
              />
              в крови, сыворотке крови и слюне склонны <br />
              к повышению;
            </li>
            <li>
              В/в инъекция CGRP<ReferenceLink text="*" href="#ref*" />
              провоцирует мигренеподобные головные боли;
            </li>
            <li>
              Эффективное лечение мигрени нормализует уровни CGRP<ReferenceLink
                text="*"
                href="#ref*"
              />
              в крови;
            </li>
            <li>
              Антагонисты рецептора CGRP<ReferenceLink text="*" href="#ref*" />
              эффективны в лечении мигрени.
            </li>
          </ul>
        </div>

        <p>
          Понимание роли кальцитонин-ген-родственного пептида
          (CGRP<ReferenceLink text="*" href="#ref*" />) в патофизиологии
          мигрени, а также достижения в области мАт облегчили разработку
          специфических профилактических терапий при мигрени, направленных на
          CGRP<ReferenceLink text="*" href="#ref*" />.
        </p>
      </div>
      <ReferencesList
        :list="referencesList"
        :terminologyList="terminologyList"
      />
    </div>
  </div>
</template>

<script>
import ReferenceLink from '../components/TevaReference/ReferenceLink.vue'
import ReferencesList from '../components/TevaReference/ReferencesList.vue'

export default {
  name: 'Home',
  components: {
    ReferenceLink,
    ReferencesList
  },
  metaInfo: {
    title: 'Патогенез мигрени',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data() {
    return {
      terminologyList: [
        {
          id: 'ref*',
          text: '*Calcitonin Gene-Related Peptide'
        }
      ],
      referencesList: [
        {
          id: 'ref1',
          text: 'http://www.nhs.uk/conditions/migraine/Pages/Introduction.aspx'
        },
        {
          id: 'ref2',
          text: 'http://www.nice.org.uk/nicemedia/live/13901/60853/60853.pdf'
        },
        {
          id: 'ref3',
          text: 'http://www.acponline.org/'
        },
        {
          id: 'ref4',
          text: 'https://healthy.kaiserpermanente.org/washington/front-door'
        },
        {
          id: 'ref5',
          text: 'https://americanheadachesociety.org/'
        },
        {
          id: 'ref6',
          text: 'http://uhs.berkeley.edu/home/healthtopics/pdf/triggers.pdf'
        },
        {
          id: 'ref7',
          text: 'Ashina M. N Engl J Med. 2020 Nov 5;383(19):1866-1876'
        },
        {
          id: 'ref8',
          text: 'Schoenen J. Rev Neurol (Paris). 2020 Dec;176(10):788-803'
        },
        {
          id: 'ref9',
          text: 'Edvinsson L. Role of CGRP in Migraine. Handb Exp Pharmacol. 2019;255:121-130. doi: 10.1007/164_2018_201. PMID: 30725283'
        },
        {
          id: 'ref10',
          text: 'Wattiez AS, Sowers LP, Russo AF. Calcitonin gene-related peptide (CGRP): role in migraine pathophysiology and therapeutic targeting. Expert Opin Ther Targets. 2020 Feb;24(2):91-100'
        },
        {
          id: 'ref11',
          text: 'Iyengar S, Johnson KW, Ossipov MH, Aurora SK. CGRP and the Trigeminal System in Migraine. Headache. 2019 May;59(5):659-681.'
        }
      ]
    }
  }
}
</script>
